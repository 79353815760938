import React from 'react';
import video1 from './video/tutorial-video_new2.mp4';
import arrowRight from 'assets/arrow-right.svg'
import styles from './Tutorial.module.scss';

type TutorialProps = {
  onAcknowledge: () => void;
};

export const Tutorial: React.FC<TutorialProps> = ({ onAcknowledge }) => {
  return (
    <div className={styles.tutorial}>
      <div className={styles.tutorialArrow}>
        <img src={arrowRight} alt="arrow-right" />
      </div>
      <video
        src={video1}
        autoPlay
        muted
        loop
        playsInline
        className={styles.video}
      />
      <h2 className={styles.title}>Изменения отображения</h2>
      <p className={styles.text}>
        Перемещая поле карточек вы можете менять в большую или меньшую сторону
        отображение карточек или карты.
      </p>
      <button type="button" className={styles.button} onClick={onAcknowledge}>
        Понятно
      </button>
    </div>
  );
};
