import React from 'react';
import ReactModal from 'react-modal';
import cc from 'classcat';
import { useTheme } from "../../../../context/theme/useTheme";
import attantionIcon from 'assets/attantionIcon.svg'
import styles from './ConfirmCloseModal.module.scss';

type ConfirmCloseModalProps = {
  isOpen: boolean;
  onRequestClose?: () => void;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmCloseModal: React.FC<ConfirmCloseModalProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  onCancel,
}) => {
  const {theme} = useTheme();

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose} // если хотите закрывать по оверлею/esc
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false} // или ReactModal.setAppElement('#root') где-то в App
      className={styles.modalContent}
      overlayClassName={cc([styles.modalOverlay, theme])}
    >
      <></>
      <div className={styles.popupInner}>
        <div className={styles.popupHeader}>
          <div className={styles.warningIcon}>
            <img src={attantionIcon} alt=''/>
          </div>
          <div className={styles.popupBody}>
            <h3 className={styles.title}>Внимание!</h3>
            <p>Вы действительно хотите закрыть окно без сохранения данных?</p>
          </div>
        </div>

        <div className={styles.popupFooter}>
          <button
            type="button"
            onClick={onCancel}
            className={cc([styles.btn, styles.btnSecondary])}
          >
            Отмена
          </button>
          <button
            type="button"
            onClick={onConfirm}
            className={cc([styles.btn, styles.btnPrimary])}
          >
            Закрыть
          </button>
        </div>
      </div>
    </ReactModal>
  );
};
