import React, {useEffect, useState, useMemo, useCallback} from 'react';
import cc from "classcat";
import { useDidMount } from 'beautiful-react-hooks';
import SwiperType from 'swiper/types/swiper-class';
import SwiperCore, { Navigation, Thumbs, Lazy } from 'swiper';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import {Album, albumService, Params} from 'services';
import { FullscreenPopup } from 'components/atoms';

import { albumsStore } from 'stores/AlbumsStore';
import './swiper.css';
import { AlbumMonthTabs } from './components/AlbumMonthTabs';
import { AlbumPhotoCounter } from './components/AlbumPhotoCounter';
import { AlbumDescription } from './components/AlbumDescription';
import { PhotosSwiper } from './components/PhotosSwiper';
import { PhotosNavigationSwiper } from './components/PhotosNavigationSwiper';
import { useGetQueryString } from '../../hooks';
import { AlbumQueryStringType } from './types';
import { toBoolean } from '../../utils';
import { albumCottageService } from "../../services/v1/villageService/album";
import { AlbumDropdownTabs } from "./components/AlbumDropdownTabs";
import styles from './styles.module.css';

export const AlbumPageRender = () => {
  const history = useHistory();
  const { complexId, albumId, cottageId } = useParams<Pick<Params, 'albumId' | 'complexId' | 'cottageId'>>();
  const isCottage = Boolean(cottageId);
  const id = complexId || cottageId;

  const { query } = useGetQueryString<AlbumQueryStringType>();
  const { hideFeedbackForm, photoId } = query;

  const { albumFilterOptions, photosToShow, albumsToShow } = albumsStore;

  useDidMount(() => SwiperCore.use([Navigation, Thumbs, Lazy]));

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeAlbum, setActiveAlbum] = useState<Album>();

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>();
  const [mainSwiper, setMainSwiper] = useState<SwiperType>();

  // --- Состояние, отвечающее за показ/скрытие управляющих элементов ---
  const [showUI, setShowUI] = useState(false);

  const photosLength = useMemo(() => photosToShow.length - 1, [photosToShow]);
  const slidesPerView = useMemo(() => Math.ceil(window.innerWidth / 130), []);

  const slideTo = (index: number) => {
    const _activeAlbum = albumsToShow.find(
      (album) => photosToShow[index]?.albumId === album.id,
    );

    setActiveAlbum(_activeAlbum);
    setActiveIndex(index);

    if (mainSwiper) {
      mainSwiper.slideTo(index);
    }
    if (thumbsSwiper) {
      thumbsSwiper.slideTo(index);
    }

    window.setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
  };

  useEffect((): void => {
    if (!id || !albumId) {
      return;
    }

    if (hideFeedbackForm !== undefined) {
      albumsStore.setHideFeedbackForm(toBoolean(hideFeedbackForm));
    }
    const albumServiceToUse = isCottage ? albumCottageService : albumService;

    void albumsStore.getSortedAlbums(id, albumId, albumServiceToUse);
  }, [mainSwiper]);

  useEffect((): void => {
    if (!complexId || !albumId) {
      return;
    }

    let startIndex;

    if (photoId) {
      startIndex = photosToShow.findIndex(
        ({ id }) => Number(id) === Number(photoId),
      );
    } else {
      startIndex = photosToShow.findIndex(
        (el) => el.albumId === Number(albumId),
      );
    }

    if (startIndex !== -1) {
      slideTo(startIndex);
    } else {
      slideTo(activeIndex);
    }
  }, [photosToShow]);

  const slideToAlbums = (albumId: number) => {
    const startIndex = photosToShow.findIndex(
      (photo) => albumId === photo.albumId,
    );

    slideTo(startIndex);
  };

  // --- Функция, которая переключает состояние показа UI ---
  const toggleUI = useCallback(() => {
    setShowUI((prev) => !prev);
  }, []);

  return (
    <FullscreenPopup
      // topHeader={
      //   <AlbumPhotoCounter
      //     photosLength={photosLength}
      //     activeIndex={activeIndex}
      //   />
      // }
      topHeader={ isCottage ? (
          <AlbumDropdownTabs
            activeIndex={activeIndex}
            slideToAlbums={slideToAlbums}
            photosToShow={photosToShow}
            albumFilterOptions={albumFilterOptions}
          />
        ) : null
      }
      header={
        isCottage ? (
          <AlbumDropdownTabs
            activeIndex={activeIndex}
            slideToAlbums={slideToAlbums}
            photosToShow={photosToShow}
            albumFilterOptions={albumFilterOptions}
          />
        ) : (
          <AlbumMonthTabs
            activeIndex={activeIndex}
            slideToAlbums={slideToAlbums}
            photosToShow={photosToShow}
            albumFilterOptions={albumFilterOptions}
          />
        )
      }
      isOpen
      onClose={history.goBack}
      isAlbum={Boolean(cottageId)}
      isShowUI={showUI}
    >
      <div className={cc([styles.swiperWrap, cottageId && styles.swiperWrapCottage])}>
        <div onClick={isCottage ? toggleUI : (() => console.log(''))}>
          <PhotosSwiper
            thumbsSwiper={thumbsSwiper}
            photosToShow={photosToShow}
            activeIndex={activeIndex}
            onSlideChange={(swiper) => {
              const { activeIndex } = swiper;
              slideTo(activeIndex);
            }}
            onSwiper={setMainSwiper}
            isCottage={Boolean(cottageId)}
          />
        </div>
        {activeAlbum?.description && (
          <AlbumDescription
            description={activeAlbum?.description}
            name={activeAlbum?.name}
            isCottage={Boolean(cottageId)}
          />
        )}
        {
          isCottage && (
            <AlbumPhotoCounter
              photosLength={photosLength}
              activeIndex={activeIndex}
              isCottage={Boolean(cottageId)}
              className={showUI ? styles.hiddenSwiper : styles.showSwiper}
            />
          )
        }
      </div>


      <PhotosNavigationSwiper
        slidesPerView={slidesPerView}
        photosToShow={photosToShow}
        activeIndex={activeIndex}
        onSwiper={setThumbsSwiper}
        isCottage={Boolean(cottageId)}
        className={showUI ? styles.hiddenSwiper : styles.showSwiper}
      />
    </FullscreenPopup>
  );
};

export const AlbumPage = observer(AlbumPageRender);
