import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { flow, get } from 'lodash/fp';
import { observer } from 'mobx-react';
import {
  Text,
  DictionaryView,
  NumberFormat,
  DictionaryBadge,
  PlaceholderImage, Alert,
} from 'components/atoms';
import {
  ApartmentDto,
  DictionaryKeys,
  getAllApartmentPlans,
  getApartmentPreview,
} from 'services';
import { MayBe } from 'types';
import { FavoritesAddButton } from 'components/containers/FavoritesAddButton';
import { OffersStatusAndAddButton } from 'components/containers/OffersStatusAndAddButton';
import { CottagePlan } from '../CottagePlan/CottagePlan';
import { CottageSquareInfo } from '../CottageSquareInfo/CottageSquareInfo';
import { Cottage } from "../../../../../../../services/v1/villageService/types";
import { PdfButton } from "../../../../../../../components/containers/PdfButton";
import LinkIcon from "../../../../../../../components/ui/LinkIcon";
import styles from './CottageHeader.module.scss';
import cc from "classcat";
import {rootProfileStore} from "../../../../../../../stores/user/RootProfileStore";
import removedIcon from "../../../../../../../assets/removedIcon.svg";
import addedIcon from "../../../../../../../assets/addedIcon.svg";

type Props = {
  apartment?: Cottage;
};

export const CottageHeaderRenderer = ({ apartment }: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [alertIcon, setAlertIcon] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [currentLayoutIndex, setCurrentLayoutIndex] = useState(0);

  const apartmentTypeRoomId = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('village_type_house'),
      )(apartment),
    [apartment],
  );
  const apartment_status = flow<any, Record<DictionaryKeys, number[]>, number[]>(
    get('catalogs'),
    get('apartment_status'),
  )(apartment);

  const apartmentPreview: MayBe<string> = useMemo(
    () => getApartmentPreview(apartment),
    [apartment],
  );
  const apartmentPlans: MayBe<string>[] = getAllApartmentPlans(apartment);

  const handleFavoriteToggle = async () => {
    const { includeApartment } =
      rootProfileStore.favoritesIdsStore;

    const isFavorite = includeApartment(apartment?.id);

    if (isFavorite) {
      setAlertMessage('Удалено из избранного');
      setAlertIcon(removedIcon);
    } else {
      setAlertMessage('Добавлено в избранное');
      setAlertIcon(addedIcon);
    }

    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 1500);
  };

  return (
    <header>
      <div className={styles.topWrapper}>
        {
          apartment?.layouts && apartment?.layouts.length > 0 && (
            <div className={styles.logo}>
              <CottagePlan apartment={apartment} />
              {/*<PlaceholderImage type="FLOOR_PLAN" />*/}
            </div>
          )
        }
        {/*<div className={styles.logo}>*/}
          {/*{apartment?.layouts && apartment?.layouts[currentLayoutIndex]?.photo && (*/}
          {/*  <img*/}
          {/*    className={styles.logoImage}*/}
          {/*    src={apartment?.layouts[currentLayoutIndex]?.photo}*/}
          {/*    alt="apartment preview"*/}
          {/*  />*/}
          {/*)}*/}

          {/*{apartment?.layouts && apartment?.layouts.length > 0 && (*/}
          {/*  <CottagePlan apartment={apartment} />*/}
          {/*)}*/}
          {/*{apartment?.layouts && apartment?.layouts.length === 0 && (*/}
          {/*  <PlaceholderImage type="FLOOR_PLAN" />*/}
          {/*)}*/}
        {/*</div>*/}

        <div className={styles.topRight}>
          <div className={styles.infoWrapper}>
            <div className={cc([styles.infoWrapperInner, apartment?.layouts && apartment?.layouts.length > 0 && styles.infoWrapperInnerMobile])}>
              {
                apartment?.layouts && apartment?.layouts.length > 0 && (
                  <DictionaryBadge
                    badgeInner
                    dictKey="apartment_status"
                    dictItemId={apartment_status}
                    className={styles.infoWrapperStatus}
                  />
                )
              }
              <div className={styles.infoWrapperInnerLeft}>
                <Text
                  align="left"
                  display="block"
                  className={styles.infoWrapperType}
                >
                  <DictionaryView
                    dictKey="village_type_house"
                    dictItemId={apartmentTypeRoomId}
                  />
                  {
                    !apartment?.layouts && (
                      <DictionaryBadge
                        badgeInner
                        dictKey="apartment_status"
                        dictItemId={apartment_status}
                        className={styles.infoWrapperStatus}
                      />
                    )
                  }
                </Text>
                <Text
                  className={styles.price_m2}
                  align="right"
                  lineHeight="XL"
                  size="XS"
                  display="block"
                  weight="bold"
                >
                  <NumberFormat value={apartment?.price_m2} format="rub_meter2" />
                </Text>
                <Text className={styles.price} align="right" size="L" display="block" weight="bold">
                  <NumberFormat value={apartment?.price} />
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>

      {apartment?.id && (
        <div className={styles.actionButtonsWrapper}>
          <OffersStatusAndAddButton apartmentId={apartment.id} className={styles.headerBtnIcon} headerMenu={true}/>
          <FavoritesAddButton apartmentId={apartment.id} className={styles.headerBtnIcon} headerMenu={true} onClick={handleFavoriteToggle}/>
          <button className={styles.headerBtnIcon}><span><LinkIcon/></span></button>
          <PdfButton className={styles.headerBtnIcon}/>
          <button className={styles.btnReadMore}>Подробнее</button>
        </div>
      )}

      <div className={styles.squareListWrapper}>
        <CottageSquareInfo apartment={apartment} />
      </div>

      {showAlert && ReactDOM.createPortal(
        <div className={styles.alertWrapper}>
          <Alert isShow={true} severity={'standard'} icon={alertIcon || ''}>
            {alertMessage}
          </Alert>
        </div>,
        document.body
      )}
    </header>
  );
};

export const CottageHeader = observer(CottageHeaderRenderer);

