import qs from 'qs';
import { API_ROUTES } from 'services/constants';
import { makeRequest } from '../../../libs/axios/makeRequest';
import { loggerService } from '../../../loggerService';
import {
  RequestPageInfo,
  ResponsePageInfo,
  ThrowableResult,
} from '../../../types';
import { ApartmentDto } from './types';
import { BuildingSearchParameters } from '../building/types';
import { authTokenStorageService } from "../../../storage";

export const apartmentsService = {
  async getApartments(
    params?: RequestPageInfo,
    filter?: BuildingSearchParameters,
  ): Promise<ThrowableResult<ResponsePageInfo<ApartmentDto[]>>> {
    try {
      const token = authTokenStorageService?.getToken();
      const response = await makeRequest<ResponsePageInfo<ApartmentDto[]>>({
        method: 'get',
        url: API_ROUTES.apartmentsList,
        headers: token ? { 'X-Auth-Token': `${token?.token}` } : {},
        params: {
          ...params,
          ...filter,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return response.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
  async getApartmentsById(
    apartmentId: number | string,
  ): Promise<ThrowableResult<ApartmentDto>> {
    try {
      const response = await makeRequest<ApartmentDto>({
        method: 'get',
        url: API_ROUTES.apartment.replace(
          ':apartmentId',
          apartmentId.toString(),
        ),
        paramsSerializer: (params) => qs.stringify(params),
      });
      return response.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
};
