import React, {useRef, useCallback, useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';
import { Link } from 'react-router-dom';
import { useId } from 'react-id-generator';

import {
  ResidentialComplexCard,
  EmptyData,
  LinkButton,
} from 'components/atoms';

import { leafletStore, complexFilterStore, buildingsStore } from 'stores';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { bindComplexToPin } from 'stores/Complex/BindComplexToPin';

import { appRoutesService } from 'services';
import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';

import { ComplexListSkeleton } from './components/ComplexListSkeleton';
import { ComplexListHeader } from './components/ComplexListHeader';
import { useComplexListOnIncrement } from './hooks/useComplexListOnIncrement';
import { useReactionToLoadComplexData } from './hooks/useReactionToLoadComplexData';
import { TelegramComponent } from './components/TelegramComponent/TelegramComponent';

import styles from './styles.module.css';
import LeafletStore from "../../../../stores/LeafletStore";
import DragHandleIcon from "../../../../components/ui/DragHandleIcon";

type ComplexListProps = {
  columns: number;
  isMobile: boolean;
  isMediumScreen: boolean;
  onWidthChange: (newWidth: number) => void;
  isSmallScreen: boolean;
};

const SNAP_POINTS = [421, 790, 1140]; // ваши фикcированные ширины

function snapWidth(width: number) {
  // Ищем ближайшую точку из SNAP_POINTS
  let closest = SNAP_POINTS[0];
  let minDiff = Math.abs(width - closest);
  for (let i = 1; i < SNAP_POINTS.length; i++) {
    const diff = Math.abs(width - SNAP_POINTS[i]);
    if (diff < minDiff) {
      minDiff = diff;
      closest = SNAP_POINTS[i];
    }
  }
  return closest;
}

export const ComplexList = observer(function ComplexListRender({
  columns,
  isMobile,
  isSmallScreen,
  isMediumScreen,
  onWidthChange,
}: ComplexListProps) {
  const { complexes, requestApi } = buildingsStore;

  // Сохраняем координату нажатия и исходную ширину
  const startXRef = useRef(0);
  const startWidthRef = useRef(0);
  // «Текущая» ширина во время «тягивания»
  const currentWidthRef = useRef(0);

  // Подгрузка при скролле
  const onScrollIncrement = useComplexListOnIncrement({
    leafletStore,
    buildingsStore,
    complexFilterStore,
  });
  // Реакция на загрузку
  useReactionToLoadComplexData({
    leafletStore,
    buildingsStore,
    complexFilterStore,
  });

  const idList = useId(complexes.data?.list.length || 0, 'complexes');

  const toggleComplexFavorites = useCallback(
    (id: number) => (evt: React.MouseEvent) => {
      evt.preventDefault();
      if (id) {
        rootProfileStore.favoritesComposeAuth.toggleComplex(id);
      }
    },
    []
  );

  // columns -> px (по вашему коду)
  const getWidthByColumns = (cols: number) => {
    if (cols === 1) return 421;
    if (cols === 2) return 790;
    return 1140;
  };

  // =========================
  // ГЛАВНАЯ ЛОГИКА ПЕРЕТЯГИВАНИЯ
  // =========================

  // Обработчик pointermove на window
  const handlePointerMove = useCallback(
    (e: PointerEvent) => {
      const diff = startXRef.current - e.clientX;
      const newWidth = startWidthRef.current + diff;
      currentWidthRef.current = newWidth;
      onWidthChange(newWidth); // промежуточно меняем ширину
    },
    [onWidthChange],
  );

  // Обработчик pointerup на window
  const handlePointerUp = useCallback(() => {
    // Удаляем подписки (иначе при следующем движении мышкой снова сработает)
    window.removeEventListener('pointermove', handlePointerMove);
    window.removeEventListener('pointerup', handlePointerUp);

    // Снэпим
    const snapped = snapWidth(currentWidthRef.current);
    onWidthChange(snapped);

    // ВАЖНО: после этого «дёрните» invalidateSize:
    if (LeafletStore.instance) {
      LeafletStore.instance.invalidateSize();
    }
  }, [handlePointerMove, onWidthChange]);

  const onPointerDown = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      e.preventDefault();
      // Запоминаем начальные координаты и ширину
      startXRef.current = e.clientX;
      startWidthRef.current = getWidthByColumns(columns);
      currentWidthRef.current = startWidthRef.current;

      // Подписываемся глобально на move/up
      window.addEventListener('pointermove', handlePointerMove);
      window.addEventListener('pointerup', handlePointerUp);

      // Если хотите, можно ещё и setPointerCapture(e.pointerId),
      // но при глобальных событиях оно уже не критично
      e.currentTarget.setPointerCapture(e.pointerId);
    },
    [columns, handlePointerMove, handlePointerUp],
  );

  // =========================
  // Стили списка
  // =========================
  const listStyle: React.CSSProperties = isMobile
    ? {}
    : {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: `repeat(${columns}, minmax(255px, 1fr))`,
      gap: '8px',
      marginBottom: '20px',
      padding: 0,
      listStyle: 'none',
    };

  return (
    <>
      {/* Ползунок только на десктопе */}
      {!isMobile && !isSmallScreen && (
        <div
          className={styles.dragHandle}
          onPointerDown={onPointerDown}
          title="Тянуть влево — шире, вправо — уже"
        >
          <DragHandleIcon columns={columns} isMediumScreen={isMediumScreen} />
        </div>
      )}
      <div className={styles.root} onScroll={onScrollIncrement}>
        <ComplexListHeader
          foundedCount={buildingsStore?.complexes?.total || 0}
          isLoading={requestApi.isLoading}
        />

        {!complexes.data?.list.length && !requestApi.isLoading && (
          <EmptyData
            title="Для вашего запроса нет результатов"
            description={
              <>
                Попробуйте изменить фильтры или{' '}
                <LinkButton onClick={complexFilterStore.filters.reset}>
                  сбросить поиск
                </LinkButton>
              </>
            }
            className={styles.emptyDataWrapper}
          />
        )}

        <TelegramComponent/>

        <ul
          {...dataTestIdToProps('ComplexList_complexList')}
          style={listStyle}
          className={styles.list}
        >
          {complexes.data?.list?.map((building, index) => (
            <li
              {...dataTestIdToProps('ComplexList_complexItem')}
              key={idList[index]}
              className={styles.listItem}
              onMouseEnter={() => {
                bindComplexToPin.setTargetId(building.id);
              }}
              onMouseLeave={() => {
                bindComplexToPin.setTargetId(null);
              }}
            >
              <Link
                to={{
                  pathname: appRoutesService.replaceRoute('view', {
                    complexId: building.id.toString(),
                  }),
                }}
                className={cc([
                  styles.card,
                  {
                    [styles.cardHover]: bindComplexToPin.targetId === building.id,
                  },
                ])}
              >
                <ResidentialComplexCard
                  buildingObject={building}
                  toggleComplexFavorites={toggleComplexFavorites}
                />
              </Link>
            </li>
          ))}
          {requestApi.isLoading && <ComplexListSkeleton/>}
        </ul>
      </div>
    </>
  );
});
