import React from 'react';
import styles from './styles.module.css';
import cc from "classcat";

type Props = {
  activeIndex: number;
  photosLength: number;
  isCottage?: boolean;
  className?: string;
};

export const AlbumPhotoCounter = ({ photosLength, activeIndex, isCottage, className }: Props) => (
  <span className={cc([styles.photoCount, isCottage && styles.photoCountCottage, className])}>
    {`Фото ${activeIndex + 1} из ${photosLength}`}
  </span>
);
