import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appRoutesService } from 'services';
import { useTheme } from "../../../../../context/theme/useTheme";
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Icon } from 'components/atoms/Icon';
import eselloLogo from 'assets/eselloNewLogo.svg';
import eselloLogoDark from 'assets/eselloLogoDark.svg';
import searchColorIcon from 'assets/searchColorIcon.svg';
import burgerIcon from 'assets/burgerIcon.svg';
import styles from './styles.module.css';

type Props = {
  onOpenSearch?: () => void;
  onOpenMenu?: () => void;
};

export const NavbarMobileRender = ({ onOpenSearch, onOpenMenu }: Props) => {
  const {theme} = useTheme();

  return (
    <div className={styles.mobileWrapper}>
      <header className={styles.content}>
        <ButtonBase
          onClick={onOpenSearch}
          color="transparent"
          icon={<Icon size={24} src={searchColorIcon}/>}
        />
        <Link to={appRoutesService.getRoute('main')} className={styles.logo}>
          <img src={theme === 'dark-theme' ? eselloLogoDark : eselloLogo} alt="site logo"/>
        </Link>
        <ButtonBase
          onClick={onOpenMenu}
          color="transparent"
          icon={<Icon size={24} src={burgerIcon}/>}
        />
      </header>
    </div>
  )
};

export const NavbarMobile = observer(NavbarMobileRender);
