import React, { FC, useEffect, useState } from 'react';
import arrowDownIcon from 'assets/arrowDownIcon.svg';
import styles from './CookieBanner.module.scss';

export const CookieBanner: FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // Проверяем, был ли пользователь уже согласен
    const cookieConsent = localStorage.getItem('cookieAccepted');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setIsVisible(false);
  };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  if (!isVisible) return null;

  return (
    <div className={styles.cookieBanner}>
      <div className={styles.cookieBanner__Inner}>
        <div className={styles.cookieBanner__text}>
          <p>
            Мы используем файлы cookie для вашего удобства пользования сайтом и повышения качества сервиса.
            <div className={styles.cookieBanner__toogle}>
              <span className={styles.link} onClick={toggleExpand}>{isExpanded ? 'Скрыть' : 'Подробнее'}</span>
              <img src={arrowDownIcon} alt='' className={!isExpanded ? styles.cookieBanner__arrowDown : styles.cookieBanner__arrowUp}/>
            </div>
          </p>

          {isExpanded && (
            <p>
              "Flatby" использует cookie для обеспечения функционирования веб-сайта,
              аналитики действий на веб-сайте и улучшения качества обслуживания.
              Для получения дополнительной информации Вы можете ознакомиться{" "}
              <span>с условиями и принципами их обработки</span>. Если Вы не хотите, чтобы эти данные
                обрабатывались, отключите cookie в настройках браузера.
              </p>
          )}
        </div>

        <button onClick={handleAccept}>Принять</button>
      </div>
    </div>
  );
};
