import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
  useMemo
} from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { villageStore } from '../../../../../../stores';
import { Section, Cottage } from '../../../../../../services/v1/villageService/types';
import { BuildingSearchParameters, appRoutesService } from '../../../../../../services';
import { PlanToggle } from '../PlanToggle/PlanToggle';
import { TableRow } from '../TableRow/TableRow';
import { CottageView } from '../CottageView/CottageView';
import { NoResults } from '../NoResults/NoResults';
import iconUpDown from 'assets/iconUpDown.svg';
import iconUpActive from 'assets/iconUpActive.svg';
import iconDownActive from 'assets/iconDownActive.svg';
import styles from './GeneralPlanTable.module.scss';

interface RouteParams {
  complexId: string;
  generalPlanId: string;
  viewType: string;
  sectionId: string;
  cottageId?: string;
  cottageNumber?: string;
}

interface GeneralPlanTableProps {
  villageId: string;
  sections: Section[];
  selectedSectionId: number | null;
  setSelectedSectionId: Dispatch<SetStateAction<number | null>>;
  filtersForAPI?: Partial<BuildingSearchParameters>;
}

export const GeneralPlanTable: FC<GeneralPlanTableProps> = observer(({
  villageId,
  sections,
  selectedSectionId,
  setSelectedSectionId,
  filtersForAPI,
}) => {
  const [cottages, setCottages] = useState<Cottage[]>([]);
  const [loadingCottages, setLoadingCottages] = useState<boolean>(false);

  // При открытии модалки с детальной инфой
  const [isApartmentViewOpen, setIsApartmentViewOpen] = useState<boolean>(false);
  const [selectedCottage, setSelectedCottage] = useState<Cottage | null>(null);

  const history = useHistory();
  const params = useParams<RouteParams>();

  // === Сортировка по цене ===
  // Три состояния: 'none' (нет сортировки), 'desc' (от большего к меньшему), 'asc' (от меньшего к большему)
  const [priceSortOrder, setPriceSortOrder] = useState<'none' | 'desc' | 'asc'>('none');

  // Переключатель сортировки: none → desc → asc → none
  const togglePriceSortOrder = () => {
    setPriceSortOrder((prev) => {
      if (prev === 'none') return 'desc';
      if (prev === 'desc') return 'asc';
      return 'none';
    });
  };

  // При загрузке определяем, какая секция выбрана по умолчанию
  useEffect(() => {
    if (sections.length > 0 && selectedSectionId === null) {
      const sectionWithCottages = sections.find((section) => section.stats.apartments > 0);
      if (sectionWithCottages) {
        setSelectedSectionId(sectionWithCottages.id);
      } else {
        // Если ни в одной секции нет коттеджей, берём первую
        setSelectedSectionId(sections[0].id);
      }
    }
  }, [sections, selectedSectionId, setSelectedSectionId]);

  // Загружаем коттеджи при смене секции
  useEffect(() => {
    if (selectedSectionId !== null) {
      setLoadingCottages(true);
      villageStore
        .fetchCottages({
          complexIds: [villageId],
          sectionIds: [selectedSectionId],
          filtersForAPI,
          limit: 10000,
          withoutSold: 1,
        })
        .then(() => {
          setCottages(villageStore.cottages);
          setLoadingCottages(false);
        });
    }
  }, [selectedSectionId, villageId, filtersForAPI]);

  // Подсчёт кол-ва объектов (для отображения в PlanToggle)
  const sectionCounts: { [key: number]: number } = {};
  sections.forEach((section) => {
    sectionCounts[section.id] = section?.stats?.apartments_without_sold || 0;
  });

  // Если в URL есть конкретный cottageId, сразу открываем модалку
  useEffect(() => {
    if (params.cottageId && cottages.length > 0) {
      const found = cottages.find((c) => c.id === Number(params.cottageId));
      if (found) {
        setSelectedCottage(found);
        setIsApartmentViewOpen(true);
      }
    }
  }, [cottages, params.cottageId]);

  // Клик на строку в таблице: открываем детальную карточку (CottageView)
  const onRowClick = (cottage: Cottage) => {
    const newParams = {
      ...params,
      cottageId: cottage.id.toString(),
      cottageNumber: cottage.number,
    };
    const newRoute = appRoutesService.replaceRoute('generalPlanCottage', newParams);
    history.push(newRoute);

    setSelectedCottage(cottage);
    setIsApartmentViewOpen(true);
  };

  // Закрываем модалку CottageView
  const closeApartmentView = () => {
    const { cottageId, cottageNumber, ...restParams } = params;
    const newRoute = appRoutesService.replaceRoute('generalPlan', restParams);
    history.push(newRoute);

    setSelectedCottage(null);
    setIsApartmentViewOpen(false);
  };

  // Если есть внешние фильтры и коттеджей нет, показываем NoResults
  const areFiltersApplied = filtersForAPI && Object.keys(filtersForAPI).length > 0;
  if (cottages.length === 0 && !loadingCottages && areFiltersApplied) {
    return <NoResults />;
  }

  // Мемоизированная сортировка коттеджей по цене
  const sortedCottages = useMemo(() => {
    if (priceSortOrder === 'none') {
      return cottages;
    } else if (priceSortOrder === 'desc') {
      return [...cottages].sort((a, b) => (b.price ?? 0) - (a.price ?? 0));
    } else { // 'asc'
      return [...cottages].sort((a, b) => (a.price ?? 0) - (b.price ?? 0));
    }
  }, [cottages, priceSortOrder]);

  return (
    <div className={styles.generalPlanTable}>
      {/* Верхняя строка: переключатель секций + кнопка сортировки */}
      <div className={styles.topRow}>
        <PlanToggle
          sections={sections}
          selectedSectionId={selectedSectionId}
          onSelectSection={(sectionId) => {
            if (sectionId !== selectedSectionId) {
              setSelectedSectionId(sectionId);
              // Сбрасываем текущие коттеджи и выбранный
              setCottages([]);
              setSelectedCottage(null);
              setIsApartmentViewOpen(false);
            }
          }}
          sectionCounts={sectionCounts}
        />

        {/* Кнопка/иконка сортировки по цене (цикл: none -> desc -> asc -> none) */}
        <div
          className={styles.sortButton}
          onClick={togglePriceSortOrder}
          title="Сортировка по цене"
        >
          {priceSortOrder === 'none' && <img src={iconUpDown} alt="Нет сортировки" />}
          {priceSortOrder === 'desc' && <img src={iconDownActive} alt="От большего к меньшему" />}
          {priceSortOrder === 'asc' && <img src={iconUpActive} alt="От меньшего к большему" />}
        </div>
      </div>

      {/* Заголовки таблицы (desktop-версия). В мобильной могут скрываться. */}
      <div className={styles.tableHeader}>
        <div>Статус</div>
        <div>План</div>
        <div>№</div>
        <div>Этажи</div>
        <div>Спальни</div>
        <div>Тип</div>
        <div>Площадь участка</div>
        <div>Площадь</div>
        <div>₽ / м²</div>
        <div>Стоимость</div>
        <div>Материал</div>
        <div>Отделка</div>
        <div></div>
      </div>

      {/* Рендерим отсортированный список коттеджей */}
      {sortedCottages.length === 0 ? (
        <div />
      ) : (
        sortedCottages.map((cottage) => {
          const isSelected = selectedCottage?.id === cottage.id;
          return (
            <TableRow
              key={cottage.id}
              data={cottage}
              onRowClick={onRowClick}
              isSelected={isSelected}
            />
          );
        })
      )}

      {/* Модалка/детальная карточка для выбранного коттеджа */}
      {selectedCottage && (
        <CottageView
          onClose={closeApartmentView}
          apartment={selectedCottage}
          isOpen={isApartmentViewOpen}
        />
      )}
    </div>
  );
});


