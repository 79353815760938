import React, { useState, useCallback } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { DropdownButton } from 'components/atoms/Button';
import dropdown from 'assets/arrowDown.svg';
import sortIcon from 'assets/sortIcon.svg'
import { RequestPageInfoStore } from '../../../stores';
import { SortByType, SortDirection } from '../../../services';
import styles from './styles.module.css';

const langAliases = {
  updated: 'Дата обновления',
  alphabetic: 'По алфавиту',
  null: 'По умолчанию',
};

type Props = {
  pageInfo: RequestPageInfoStore;
  onChange: (sort?: SortByType, direction?: SortDirection) => void;
};

/** @todo: переделать */
/** @deprecated */
export const SortDropdownRender = ({ pageInfo, onChange }: Props) => {
  const [isSortVisible, setIsSortVisible] = useState(false);

  const handleSortVisibility = useCallback(() => {
    setIsSortVisible(!isSortVisible);
  }, [isSortVisible]);

  const sortVariants = (
    <div className={styles.overlay}>
      <button
        type="button"
        onClick={() => onChange()}
        className={cc([
          styles.item,
          {
            [styles.itemActive]:
              !pageInfo.pageInfo.sort && !pageInfo.pageInfo.sortDirection,
          },
        ])}
      >
        По умолчанию
      </button>

      <button
        type="button"
        onClick={() => onChange('updated', 'asc')}
        className={cc([
          styles.item,
          {
            [styles.itemActive]:
              pageInfo.pageInfo.sort === 'updated' &&
              pageInfo.pageInfo.sortDirection === 'asc',
          },
        ])}
      >
        Сначала старые
      </button>

      <button
        type="button"
        onClick={() => onChange('updated', 'desc')}
        className={cc([
          styles.item,
          {
            [styles.itemActive]:
              pageInfo.pageInfo.sort === 'updated' &&
              pageInfo.pageInfo.sortDirection === 'desc',
          },
        ])}
      >
        Сначала новые
      </button>

      <button
        type="button"
        onClick={() => onChange('alphabetic', 'asc')}
        className={cc([
          styles.item,
          {
            [styles.itemActive]:
              pageInfo.pageInfo.sort === 'alphabetic' &&
              pageInfo.pageInfo.sortDirection === 'asc',
          },
        ])}
      >
        По алфавиту А-Я
      </button>

      <button
        type="button"
        onClick={() => onChange('alphabetic', 'desc')}
        className={cc([
          styles.item,
          {
            [styles.itemActive]:
              pageInfo.pageInfo.sort === 'alphabetic' &&
              pageInfo.pageInfo.sortDirection === 'desc',
          },
        ])}
      >
        По алфавиту Я-А
      </button>
    </div>
  );

  return (
    <div className={styles.sortWrapper}>
      <img src={sortIcon} className={styles.label} />
      <DropdownButton
        overlayContent={sortVariants}
        variant="text"
        visible={isSortVisible}
        onVisibleChange={handleSortVisibility}
        dropDownClassName={styles.dropdown}
        className={styles.dropdownButton}
      >
        <span style={{ display: 'flex' }}>
          {langAliases[pageInfo.pageInfo.sort || 'null']}
          <img
            style={{ marginLeft: '5px' }}
            src={dropdown}
            alt="open"
            className={isSortVisible ? styles.dropdownButtonImg : ''}
          />
        </span>
      </DropdownButton>
    </div>
  );
};

export const SortDropdown = observer(SortDropdownRender);
