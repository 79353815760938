import React from 'react';
import { useTheme } from "../../context/theme/useTheme";

const AddFavoriteIconSmall = ({ isFavorite }) => {
  const { theme } = useTheme();
  return (
    isFavorite ?
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.15 2C9.932 2 8.763 2.57384 8 3.48065C7.237 2.57384 6.068 2 4.85 2C2.694 2 1 3.71444 1 5.89646C1 8.57439 3.38 10.7564 6.985 14.0719L8 15L9.015 14.0649C12.62 10.7564 15 8.57439 15 5.89646C15 3.71444 13.306 2 11.15 2Z"
          fill="#EE5656"/>
      </svg>
      : theme === 'dark-theme' ? (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.34648 13.1892L13.0363 8.22816C14.1886 7.00918 14.3586 5.00378 13.2681 3.72272C12.995 3.40091 12.6627 3.14135 12.2916 2.95987C11.9205 2.77838 11.5183 2.67879 11.1096 2.66717C10.7009 2.65554 10.2942 2.73212 9.91445 2.89224C9.53465 3.05236 9.18967 3.29266 8.90056 3.59848L8.01909 4.53093L7.25803 3.72585C6.1057 2.50687 4.20994 2.32703 2.99893 3.4806C2.69471 3.76955 2.44934 4.12104 2.27778 4.51362C2.10622 4.9062 2.01208 5.33164 2.00109 5.76398C1.9901 6.19632 2.06249 6.6265 2.21386 7.02826C2.36522 7.43003 2.59238 7.79496 2.88148 8.10079L7.69169 13.1892C7.77852 13.2811 7.89629 13.3327 8.01909 13.3327C8.14188 13.3327 8.25965 13.2811 8.34648 13.1892Z"
            stroke="#585B68" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      ) : (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.34648 13.1892L13.0363 8.22816C14.1886 7.00918 14.3586 5.00378 13.2681 3.72272C12.995 3.40091 12.6627 3.14135 12.2916 2.95987C11.9205 2.77838 11.5183 2.67879 11.1096 2.66717C10.7009 2.65554 10.2942 2.73212 9.91445 2.89224C9.53465 3.05236 9.18967 3.29266 8.90056 3.59848L8.01909 4.53093L7.25803 3.72585C6.1057 2.50687 4.20994 2.32703 2.99893 3.4806C2.69471 3.76955 2.44934 4.12104 2.27778 4.51362C2.10622 4.9062 2.01208 5.33164 2.00109 5.76398C1.9901 6.19632 2.06249 6.6265 2.21386 7.02826C2.36522 7.43003 2.59238 7.79496 2.88148 8.10079L7.69169 13.1892C7.77852 13.2811 7.89629 13.3327 8.01909 13.3327C8.14188 13.3327 8.25965 13.2811 8.34648 13.1892Z"
            stroke="#AFB4CB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      )
  );
};

export default AddFavoriteIconSmall;
