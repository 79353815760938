import React, { useCallback, useState } from 'react';
import { useId } from 'react-id-generator';
import { Popover } from 'react-tiny-popover';
import { useTheme } from "../../../context/theme/useTheme";
import { useWillUnmount } from 'beautiful-react-hooks';
import cc from 'classcat';
import dropdown from 'assets/dropdown.svg';
import { flow } from 'lodash/fp';
import { TabButton, TabProps } from '../Tabs';
import { Icon } from '../../atoms/Icon';
import { BottomSheet } from "../../atoms/BottomSheet/BottomSheet";
import closeIcon from 'assets/closeIconLight.svg'
import closeIconDark from "../../../assets/closeIconDark.svg";
import styles from './styles.module.css';

type Props<TabItemPayload> = Omit<TabProps<TabItemPayload>, 'setTabList'>;

/**
 * @desc компонент для представления табов в виде селекта
 * */
export const TabsSelect = <TabItemPayload extends {}>({
  currentActiveTab,
  tabList,
  changeTab,
}: Props<TabItemPayload>) => {
  const idList: string[] = useId(tabList.length, 'TabsSelect');
  const [isOpen, setOpen] = useState(false);
  const {theme} = useTheme();

  const handleSortVisibility = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const currentTab = tabList[currentActiveTab];

  useWillUnmount(() => setOpen(false));

  const popoverContent = (
    <div className={styles.overlay}>
      {tabList.map((item, index) => (
        <div
          onClick={flow(changeTab(index), () => setOpen(false))}
          key={idList[index]}
          className={cc([
            styles.item,
            {
              [styles.itemActive]: index === currentActiveTab,
            },
          ])}
        >
          <TabButton {...item} />
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div
        onClick={handleSortVisibility}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <TabButton {...currentTab} />
        <Icon
          size={10}
          style={{
            marginLeft: '5px',
            transform: isOpen ? '' : 'rotate(180deg)',
          }}
          src={dropdown}
        />
      </div>

      <BottomSheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        minHeight={200}
        withBackdrop={true}
      >
        <div className={styles.bottomSheetHeader}>
          <h2>Выберите уровень</h2>
          <button className={styles.closeBtn} onClick={() => setOpen(false)}>
            <img src={theme === 'dark-theme' ? closeIcon : closeIconDark} alt="close"/>
          </button>
        </div>

        <div className={styles.mobileTabsSelect}>
          {tabList.map((item, index) => (
            <button
              key={idList[index]}
              type="button"
              onClick={() => {
                // Вызываем changeTab(index) + закрываем шторку
                changeTab(index)();
                setOpen(false);
              }}
              className={cc([
                styles.item,
                {
                  [styles.itemActive]: index === currentActiveTab,
                },
              ])}
              style={{
                display: 'block',
                width: '100%',
                padding: '8px',
                textAlign: 'left',
                border: 'none',
                borderRadius: '4px',
                background: index === currentActiveTab ? '' : 'transparent',
                cursor: 'pointer',
              }}
            >
              <TabButton {...item} />
            </button>
          ))}
        </div>
      </BottomSheet>
    </>
);
};
