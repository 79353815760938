import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';

import MapIcon from 'assets/MapIcon.svg';
import UlIcon from 'assets/UlIcon.svg';

import useWindowSize from 'hooks/useWindowSize';
import { PageWrapper, Icon } from 'components/atoms';
import { ComplexPage } from 'pages/ComplexPage';
import { GeneralPlanVillage } from 'pages/LayoutPage/pages/GeneralPlanVillage';
import { PrivacyPage } from 'pages/PrivacyPage';
import { AuthPage } from 'pages/AuthPage';

import { MainPageMap } from './containers/LeafletMap';
import { SearchAndFilterBar } from './containers/SearchAndFilterBar';

import { Route, Switch } from 'react-router';
import { appRoutesService } from 'services';

import styles from './styles.module.css';
import { ComplexList } from './containers/ComplexList';
import { Tutorial } from './containers/ComplexList/components/Tutorial/Tutorial';

const MIN_WIDTH = 421;   // соответствует 1 колонке
const MID_WIDTH = 790;   // соответствует 2 колонкам
const MAX_WIDTH = 1140;  // соответствует 3 колонкам

/**
 * Функция, которая по ширине возвращает 1, 2 или 3 колонки,
 * если не учитывать «принудительные» правила для отдельных диапазонов экрана.
 */
function calcColumns(width: number): number {
  if (width < (MIN_WIDTH + MID_WIDTH) / 2) {
    return 1;
  }
  if (width < (MID_WIDTH + MAX_WIDTH) / 2) {
    return 2;
  }
  return 3;
}

const Main = () => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < 1024;                // до 1024
  const isSmallScreen = windowWidth >= 1024 && windowWidth < 1280;  // 1024–1279
  const isMediumScreen = windowWidth >= 1280 && windowWidth < 1650; // 1280–1649
  console.log('isSmallScreen', isSmallScreen)
  const [complexesWidth, setComplexesWidth] = useState(MID_WIDTH);
  const [columns, setColumns] = useState(() => calcColumns(MID_WIDTH));
  const [isActiveMap, setActiveMap] = useState(false);
  const [isActiveComplexes, setActiveComplexes] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);

  const toggleViewComplexesAndMap = () => {
    if (isActiveMap) {
      setActiveMap(false);
      setActiveComplexes(true);
    } else {
      setActiveMap(true);
      setActiveComplexes(false);
    }
  };

  const toggleViewComplexesAndMapButtonIcon = isActiveMap ? UlIcon : MapIcon;

  const finalWidth = isMobile ? '100vw' : `${complexesWidth}px`;

  const handleAcknowledge = () => {
    localStorage.setItem('tutorialSeen', 'true');
    setShowTutorial(false);
  };

  /**
   * Подгоняем ширину комплексов и пересчитываем колонки.
   * Если сейчас экран в диапазоне isSmallScreen, задаём 1 колонку,
   * если isMediumScreen — максимум 2, иначе используем calcColumns.
   */
  const handleWidthChange = useCallback(
    (newWidth: number) => {
      // Не даём вылезать за пределы MIN_WIDTH и MAX_WIDTH (или MID_WIDTH, если isMediumScreen)
      let maxWidth = MAX_WIDTH;
      if (isSmallScreen) {
        maxWidth = MIN_WIDTH; // чтобы при «перетаскивании» сильно не расширялось
      } else if (isMediumScreen) {
        maxWidth = MID_WIDTH;
      }

      let w = Math.max(MIN_WIDTH, Math.min(newWidth, maxWidth));
      let calculatedColumns = calcColumns(w);

      if (isSmallScreen) {
        calculatedColumns = 1;
      } else if (isMediumScreen && calculatedColumns > 2) {
        calculatedColumns = 2;
      }

      setComplexesWidth(w);
      setColumns(calculatedColumns);
    },
    [isSmallScreen, isMediumScreen],
  );

  useEffect(() => {
    if (isSmallScreen) {
      setComplexesWidth(MIN_WIDTH);
    }
  }, [isSmallScreen]);

  // Показываем туториал, если ещё не смотрели
  useEffect(() => {
    const tutorialSeen = localStorage.getItem('tutorialSeen');
    if (!tutorialSeen) {
      setShowTutorial(true);
    }
  }, []);

  /**
   * Если пользователь вручную растягивал панель (complexesWidth),
   * но при смене ширины окна оказываемся в isSmallScreen,
   * то нужно «форсировать» 1 колонку.
   */
  useEffect(() => {
    if (isMobile) {
      // На мобильных всегда 100% ширины, 1 экран + свайп
      setColumns(1);
    } else if (isSmallScreen) {
      setColumns(1);
    } else if (isMediumScreen && columns > 2) {
      setColumns(2);
    } else if (!isSmallScreen && !isMediumScreen) {
      // Большие экраны
      setColumns(calcColumns(complexesWidth));
    }
  }, [isMobile, isSmallScreen, isMediumScreen, complexesWidth, columns]);

  return (
    <>
      {showTutorial && !isMobile && <div className={styles.overlay}></div>}

      {showTutorial && !isMobile && (
        <Tutorial onAcknowledge={handleAcknowledge} />
      )}

      <PageWrapper
        className={styles.root}
        style={{ width: isMobile ? '100vw' : '100%' }}
      >
        <div className={styles.content}>
          <div className={styles.searchAndFilter}>
            <SearchAndFilterBar />
          </div>

          <div
            className={cc([
              styles.map,
              {
                [styles.map_show]: isActiveMap,
                [styles.map_hide]: isActiveComplexes,
              },
            ])}
          >
            <MainPageMap columns={columns} />
          </div>

          <div
            className={cc([
              styles.complexes,
              showTutorial && !isMobile && styles.complexesTutorial,
              {
                [styles.complexes_show]: isActiveComplexes,
                [styles.complexes_hide]: isActiveMap,
              },
            ])}
            style={{ width: finalWidth }}
          >
            <ComplexList
              columns={columns}
              onWidthChange={handleWidthChange}
              isMobile={isMobile}
              isSmallScreen={isSmallScreen}
              isMediumScreen={isMediumScreen}
            />
          </div>
        </div>
      </PageWrapper>

      <Switch>
        <Route path={appRoutesService.getRoute('view')} component={ComplexPage} />
        <Route
          path={appRoutesService.getRoute('generalPlan')}
          component={GeneralPlanVillage}
        />
        <Route path={appRoutesService.getRoute('privacy')}>
          <PrivacyPage />
        </Route>
        <AuthPage parentRoute="" />
      </Switch>

      {/* Кнопка-переключатель видимости (только мобилка) */}
      <button
        type="button"
        onClick={toggleViewComplexesAndMap}
        className={styles.toggleViewComplexesAndMap}
      >
        <Icon src={toggleViewComplexesAndMapButtonIcon} size={32} />
      </button>
    </>
  );
};

export default observer(Main);
