import React, { ReactNode } from 'react';
import cc from 'classcat';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import closeColorIcon from 'assets/closeColorIcon.svg';
import { Icon } from '../../../../Icon';
import { useTheme } from "../../../../../../context/theme/useTheme";
import styles from './styles.module.css';

type Props = {
  IconBack?: ReactNode;
  headerChildren?: ReactNode;
  onClose: () => void;
  className?: string;
};

export const MobilePopupHeader = ({
  IconBack,
  headerChildren,
  onClose,
  className,
}: Props) => {
  const {theme} = useTheme();

  return (
    <div className={cc([styles.headerWrapper, className])}>
      <header className={styles.header}>
        <ButtonBase
          className={styles.closeButton}
          onClick={onClose}
          color="transparent"
          icon={IconBack || <Icon size={24} src={closeColorIcon}/>}
        />
        {headerChildren && (
          <div className={styles.headerContent}>{headerChildren}</div>
        )}
      </header>
    </div>
  )
};
