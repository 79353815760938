import React from 'react';

type Props = {
  columns: number;
  isMediumScreen: boolean;
};

const DragHandleIcon = ({ columns, isMediumScreen }: Props) => {
  if (columns === 3 && !isMediumScreen) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 17L4 12L8 7M12 20L12 4M16 17L20 12L16 7"
          stroke="#F2F2F2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 20L12 4M16 17L20 12L16 7"
          stroke="#AFB4CB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (columns === 2 && !isMediumScreen) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        style={{ pointerEvents: 'none' }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 17L4 12L8 7M12 20L12 4M16 17L20 12L16 7"
          stroke="#AFB4CB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (columns === 2 && isMediumScreen) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 17L4 12L8 7M12 20L12 4M16 17L20 12L16 7"
          stroke="#F2F2F2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 20L12 4M16 17L20 12L16 7"
          stroke="#AFB4CB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (columns === 1) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16 17L20 12L16 7M12 20L12 4M8 17L4 12L8 7"
          stroke="#F2F2F2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 20L12 4M8 17L4 12L8 7"
          stroke="#AFB4CB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return null;
  }
};

export default DragHandleIcon;
