import React, { ReactNode } from 'react';
import cc from 'classcat';
import emptyDataIcon from 'assets/emptyDataIcon.svg';
import styles from './EmptyData.module.css';

type EmptyDataProps = {
  title: ReactNode;
  description?: ReactNode;
  image?: string;
  className?: string;
};

export const EmptyData = ({
  title,
  description,
  image,
  className,
}: EmptyDataProps) => {
  const containerClassName = cc([styles.container, className]);

  return (
    <div className={containerClassName}>
      <img src={emptyDataIcon} alt="emptyDataIcon" />
      <h2>{title}</h2>
      {description && <p>{description}</p>}
      {image && <img src={image} alt="no data" />}
    </div>
  );
};
