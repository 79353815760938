import React from 'react';
import { useTheme } from "../../context/theme/useTheme";

const FilterIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 1C5.37935 0.999675 4.77387 1.19186 4.26702 1.55006C3.76016 1.90826 3.37688 2.41484 3.17 3H1C0.447716 3 0 3.44772 0 4C0 4.55229 0.447715 5 1 5H3.17C3.3766 5.58553 3.75974 6.09257 4.2666 6.45121C4.77346 6.80985 5.37909 7.00245 6 7.00245C6.62091 7.00245 7.22654 6.80985 7.7334 6.45121C8.24026 6.09257 8.6234 5.58553 8.83 5H15C15.5523 5 16 4.55229 16 4C16 3.44772 15.5523 3 15 3H8.83C8.62312 2.41484 8.23984 1.90826 7.73298 1.55006C7.22613 1.19186 6.62065 0.999675 6 1ZM5 4C5 3.73478 5.10536 3.48043 5.29289 3.29289C5.48043 3.10536 5.73478 3 6 3C6.26522 3 6.51957 3.10536 6.70711 3.29289C6.89464 3.48043 7 3.73478 7 4C7 4.26522 6.89464 4.51957 6.70711 4.70711C6.51957 4.89464 6.26522 5 6 5C5.73478 5 5.48043 4.89464 5.29289 4.70711C5.10536 4.51957 5 4.26522 5 4ZM10 9C9.37935 8.99967 8.77387 9.19186 8.26702 9.55006C7.76016 9.90826 7.37688 10.4148 7.17 11H1C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13H7.17C7.3766 13.5855 7.75974 14.0926 8.2666 14.4512C8.77346 14.8099 9.37909 15.0025 10 15.0025C10.6209 15.0025 11.2265 14.8099 11.7334 14.4512C12.2403 14.0926 12.6234 13.5855 12.83 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H12.83C12.6231 10.4148 12.2398 9.90826 11.733 9.55006C11.2261 9.19186 10.6207 8.99967 10 9ZM9 12C9 11.7348 9.10536 11.4804 9.29289 11.2929C9.48043 11.1054 9.73478 11 10 11C10.2652 11 10.5196 11.1054 10.7071 11.2929C10.8946 11.4804 11 11.7348 11 12C11 12.2652 10.8946 12.5196 10.7071 12.7071C10.5196 12.8946 10.2652 13 10 13C9.73478 13 9.48043 12.8946 9.29289 12.7071C9.10536 12.5196 9 12.2652 9 12Z"
            fill="#5B5B61"/>
        </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 1C5.37935 0.999675 4.77387 1.19186 4.26702 1.55006C3.76016 1.90826 3.37688 2.41484 3.17 3H1C0.447716 3 0 3.44772 0 4C0 4.55229 0.447715 5 1 5H3.17C3.3766 5.58553 3.75974 6.09257 4.2666 6.45121C4.77346 6.80985 5.37909 7.00245 6 7.00245C6.62091 7.00245 7.22654 6.80985 7.7334 6.45121C8.24026 6.09257 8.6234 5.58553 8.83 5H15C15.5523 5 16 4.55229 16 4C16 3.44772 15.5523 3 15 3H8.83C8.62312 2.41484 8.23984 1.90826 7.73298 1.55006C7.22613 1.19186 6.62065 0.999675 6 1ZM5 4C5 3.73478 5.10536 3.48043 5.29289 3.29289C5.48043 3.10536 5.73478 3 6 3C6.26522 3 6.51957 3.10536 6.70711 3.29289C6.89464 3.48043 7 3.73478 7 4C7 4.26522 6.89464 4.51957 6.70711 4.70711C6.51957 4.89464 6.26522 5 6 5C5.73478 5 5.48043 4.89464 5.29289 4.70711C5.10536 4.51957 5 4.26522 5 4ZM10 9C9.37935 8.99967 8.77387 9.19186 8.26702 9.55006C7.76016 9.90826 7.37688 10.4148 7.17 11H1C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13H7.17C7.3766 13.5855 7.75974 14.0926 8.2666 14.4512C8.77346 14.8099 9.37909 15.0025 10 15.0025C10.6209 15.0025 11.2265 14.8099 11.7334 14.4512C12.2403 14.0926 12.6234 13.5855 12.83 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H12.83C12.6231 10.4148 12.2398 9.90826 11.733 9.55006C11.2261 9.19186 10.6207 8.99967 10 9ZM9 12C9 11.7348 9.10536 11.4804 9.29289 11.2929C9.48043 11.1054 9.73478 11 10 11C10.2652 11 10.5196 11.1054 10.7071 11.2929C10.8946 11.4804 11 11.7348 11 12C11 12.2652 10.8946 12.5196 10.7071 12.7071C10.5196 12.8946 10.2652 13 10 13C9.73478 13 9.48043 12.8946 9.29289 12.7071C9.10536 12.5196 9 12.2652 9 12Z"
          fill="#AFB4CB"/>
      </svg>
    )
  );
};

export default FilterIcon;
