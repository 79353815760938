import React, {memo, useState} from 'react';
import { useParams } from 'react-router-dom';
import { buildingFilters } from 'stores';
import { observer } from 'mobx-react';
import { Params } from 'services';
import cc from 'classcat';
import { BaseLink, Icon } from 'components/atoms';
import { TabsView } from 'components/containers/Tabs';
import { LayoutFilters } from '../LayoutFilters';
import { useLayoutTabsState } from '../../hooks/useLayoutTabsState';
import { ApartmentViewFilterWithShowCondition } from '../ApartmentViewFilterWithShowCondition';
import { TabButton } from "../../pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabButton";
import { TabList } from "../../pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabList";
import { useTheme } from "../../../../context/theme/useTheme";
import GenplanIcon from "../../../../components/ui/GenplanIcon";
import GenplanListIcon from "../../../../components/ui/GenplanListIcon";
import ArrowLeftIcon from "../../../../components/ui/ArrowLeftIcon";
import {LayoutPageHeaderMobile} from "../../containers/LayoutPageHeaderMobile";
import options from "../../../../assets/optionsColorIcon.svg";
import optionsDark from "../../../../assets/optionsDarkIcon.svg";
import arrowLeft from 'assets/darkArrowLeft.svg';
import arrowLeftIcon from 'assets/arrowLeftIcon.svg';
import arrowLeftDark from 'assets/lightArrowLeft.svg';
import { ButtonBase } from "../ButtonBase";
import useWindowSize from "../../../../hooks/useWindowSize";
import GenplanIconMobile from "../../../../components/ui/GenplanIconMobile";
import GenplanTableIconMobile from "../../../../components/ui/GenplanTableIconMobile";
import styles from './styles.module.css';

type Props = {
  showAllFiltersPopup: () => void;
  isVillage?: boolean;
  changeTabNew: (number: number) => void;
  currentActiveTabNew?: number;
} & Pick<ReturnType<typeof useLayoutTabsState>, 'currentActiveTab'> &
  Pick<ReturnType<typeof useLayoutTabsState>, 'tabList'> &
  Pick<ReturnType<typeof useLayoutTabsState>, 'changeTab'>;

const LayoutPageHeaderRight = {
  tabsView: 'LayoutPageHeaderRight',
};

export const HeaderRightRender = ({
  currentActiveTab,
  tabList,
  changeTab,
  showAllFiltersPopup,
  isVillage = false,
  changeTabNew,
  currentActiveTabNew,
}: Props) => {
  const { complexId } = useParams<Pick<Params, 'complexId'>>();
  const { width } = useWindowSize();
  const { theme } = useTheme();

  return (
    <div className={cc([styles.wrapper, isVillage && styles.wrapperVillage])}>
      {!isVillage && <ApartmentViewFilterWithShowCondition/>}

      {
        width && width > 768 ? (
          <div className={isVillage ? styles.headerRightFilter : ''}>
            <LayoutFilters
              onFilterOpen={showAllFiltersPopup}
              onFilterReset={buildingFilters.filters.reset}
              resultedFilters={buildingFilters.filters.filterToApiFormat}
            />
          </div>
        ) : (
          <ButtonBase
            onClick={showAllFiltersPopup}
            color="transparent"
            icon={<Icon src={theme === 'dark-theme' ? optionsDark : options} size={24} alt="открыть фильтр"/>}
            className={styles.villageFiltersBtn}
          />
        )
      }

      {isVillage && (
        <TabList currentActiveTab={currentActiveTabNew} tabCount={2}>
          <TabButton
            label={width && width > 768 ? "Генплан" : ''}
            onClick={() => changeTabNew(0)}
            isActive={currentActiveTabNew === 0}
            tabIcon={width && width > 768 ? <GenplanIcon isActive={currentActiveTabNew === 0} /> : <GenplanIconMobile isActive={currentActiveTabNew === 0} />}
          />
          <TabButton
            label={width && width > 768 ? "Список" : ""}
            onClick={() => changeTabNew(1)}
            isActive={currentActiveTabNew === 1}
            tabIcon={width && width > 768 ? <GenplanListIcon isActive={currentActiveTabNew === 1} /> : <GenplanTableIconMobile isActive={currentActiveTabNew === 1} />}
          />
        </TabList>
      )}

      {
        !isVillage && (
          <TabsView
            testId={LayoutPageHeaderRight.tabsView}
            tabList={tabList}
            changeTab={changeTab}
            currentActiveTab={currentActiveTab}
          />
        )
      }

      {
        width && width > 768 ? (
          <BaseLink
            path="view"
            params={{
              complexId,
            }}
            icon={isVillage ? <ArrowLeftIcon /> : <img style={{width: '16px'}} src={arrowLeft} alt=""/>}
            className={isVillage ? styles.headerRightButton : ''}
          >
            {isVillage ? 'На страницу КП' : 'На страницу ЖК'}
          </BaseLink>
        ) : (
          <BaseLink
            path="view"
            params={{
              complexId,
            }}
            icon={<img style={{width: '24px'}} src={theme === 'dark-theme' ? arrowLeftDark : arrowLeftIcon} alt=""/>}
            className={isVillage ? styles.headerRightButton : ''}
          />
        )
      }
    </div>
  );
};

export const HeaderRight = memo(observer(HeaderRightRender));
