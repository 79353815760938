import React from 'react';
import {useTheme} from "../../context/theme/useTheme";

const AddOffersIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.5526 8.71429V9.21429H12.0526H14.5V9.64286H12.0526H11.5526V10.1429V12.5H11.0789V10.1429V9.64286H10.5789H8.13158V9.21429H10.5789H11.0789V8.71429V6.35714H11.5526V8.71429ZM1.5 6.78571V6.35714H8.60526V6.78571H1.5ZM1.5 3.92857V3.5H8.60526V3.92857H1.5ZM5.65789 9.21429V9.64286H1.5V9.21429H5.65789Z"
          fill="#445EE7" stroke="#585B68"/>
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.5526 8.71429V9.21429H12.0526H14.5V9.64286H12.0526H11.5526V10.1429V12.5H11.0789V10.1429V9.64286H10.5789H8.13158V9.21429H10.5789H11.0789V8.71429V6.35714H11.5526V8.71429ZM1.5 6.78571V6.35714H8.60526V6.78571H1.5ZM1.5 3.92857V3.5H8.60526V3.92857H1.5ZM5.65789 9.21429V9.64286H1.5V9.21429H5.65789Z"
          fill="#4E6AFF" stroke="#AFB4CB"/>
      </svg>
    )
  );
};

export default AddOffersIcon;
