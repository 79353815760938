import React, { useState, useRef, useEffect } from 'react';
import cc from 'classcat';
import { AlbumFilterOption } from 'stores/AlbumsStore';
import { AlbumPhotoToFilter } from 'services';
import folderIcon from 'assets/folderIcon.svg';
import downArrowIcon from 'assets/arrowDropdownWhite.svg';
import useWindowSize from "../../../../hooks/useWindowSize";
import { BottomSheet } from "../../../../components/atoms/BottomSheet/BottomSheet";
import closeIcon from "../../../../assets/closeIconDark.svg";
import styles from './styles.module.css';

type Props = {
  albumFilterOptions: AlbumFilterOption[];
  slideToAlbums: (albumId: number) => void;
  activeIndex: number;
  photosToShow: AlbumPhotoToFilter[];
};

export const AlbumDropdownTabs = ({
  albumFilterOptions,
  photosToShow,
  slideToAlbums,
  activeIndex,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentAlbumId = photosToShow[activeIndex]?.albumId;
  const currentAlbum = albumFilterOptions.find(
    (album) => album.albumId === currentAlbumId,
  );
  const { width } = useWindowSize();

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !(dropdownRef.current as any).contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (albumId: number) => {
    slideToAlbums(albumId);
    setIsOpen(false);
  };

  return (
    <div className={styles.albumDropdown} ref={dropdownRef}>
      <div className={styles.albumDropdownName}>
        Альбомы
      </div>
      <div className={styles.albumDropdownLine}></div>
      <div
        className={styles.albumDropdownHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={folderIcon} alt="Folder Icon" className={styles.folderIcon} />
        <span className={styles.albumName}>{currentAlbum?.title}</span>
        <img
          src={downArrowIcon}
          alt="Down Arrow"
          className={cc([
            styles.arrowIcon,
            { [styles.arrowIconOpen]: isOpen },
          ])}
        />
      </div>
      {isOpen && width && width > 768 ? (
          <div className={styles.albumDropdownList}>
            {albumFilterOptions.map(({ albumId, title }) => (
              <div
                key={albumId}
                className={styles.albumDropdownItem}
                onClick={() => handleSelect(albumId)}
              >
                {title}
              </div>
            ))}
          </div>
        ) : (
          <BottomSheet
            isOpen={isOpen && !!width && width <= 768}
            onClose={() => setIsOpen(false)}
            minHeight={200}
            withBackdrop
          >
            <div className={styles.bottomSheetHeader}>
              <h2>Выберите альбом</h2>
              <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
                <img src={closeIcon} alt="close"/>
              </button>
            </div>
            <div className={styles.albumNameBlock}>
              {albumFilterOptions.map(({albumId, title}) => {
                const isActive = albumId === currentAlbumId;

                return (
                  <div
                    key={albumId}
                    onClick={() => handleSelect(albumId)}
                    className={cc([styles.albumDropdownItemMobile, isActive && styles.albumDropdownItemMobileActive])}
                  >
                    {title}
                  </div>
                )
              })}
            </div>
          </BottomSheet>
      )
      }
    </div>
  );
};
