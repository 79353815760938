import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Layer } from 'leaflet';
import { reaction } from 'mobx';
import { isNil } from 'lodash';
import { Marker, Tooltip } from 'react-leaflet';
import { PinDetails } from 'components/atoms';
import { bindComplexToPin } from 'stores/Complex/BindComplexToPin';
import { appRoutesService, Pin } from 'services';
import { observer } from 'mobx-react';
import markerStyles from '../MapMarker/styles.module.css';
import { MapPoint } from '../MapMarker';

type Props = { pin: Pin };

export const MarketTooltipRender = ({ pin }: Props) => {
  const history = useHistory();
  const ref = useRef<Layer>();

  useEffect(
    () =>
      reaction(
        () => bindComplexToPin.targetId,
        (targetId, prevTargetId) => {
          if (pin.id === prevTargetId && pin.id !== targetId) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref.current._source.fire('mouseout');
            return;
          }
          if (pin.id !== prevTargetId && pin.id === targetId) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref.current._source.fire('mouseover');
            return;
          }
          if (isNil(targetId) && pin.id === prevTargetId) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref.current._source.fire('mouseout');
          }
        },
      ),
    [pin],
  );

  return (
    <Marker
      position={[+pin.latitude, +pin.longitude]}
      eventHandlers={{
        click: () => {
          if (pin?.id) {
            history.push(
              appRoutesService.replaceRoute('view', {
                complexId: pin?.id.toString(),
              }),
            );
          }
        },
        mouseover: (e) => {
          bindComplexToPin.setTargetId(pin.id);
          e.target._icon.classList.add(markerStyles.point_active);
        },
        mouseout: (e) => {
          bindComplexToPin.setTargetId(null);
          e.target._icon.classList.remove(markerStyles.point_active);
        },
      }}
      icon={MapPoint}
    >
      <Tooltip ref={ref as any} opacity={1}>
        <PinDetails pin={pin} />
      </Tooltip>
    </Marker>
  );
};

export const MarketTooltip = observer(MarketTooltipRender);
