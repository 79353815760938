import React, { ReactNode, useCallback, useState } from 'react';
import { MobilePopupHeader } from 'components/atoms/Popup/MobilePopup/components/MobilePopupHeader';
import cc from 'classcat';
import { Icon } from '../../../../../../components/atoms/Icon';
import arrowLeftColor from '../../../../../../assets/arrowLeftColor.svg';
import { Text } from '../../../../../../components/atoms/Text';
import { ApartmentDto } from '../../../../../../services';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { useApartmentViewHammer } from '../../hooks/useApartmentViewHammer';
import { BottomSheet } from '../../../../../../components/atoms/BottomSheet/BottomSheet';
import styles from './styles.module.css';

type Props = {
  onClose?: () => void;
  children?: ReactNode;
  isOpen: boolean;
  isCottage?: boolean;
} & Pick<ApartmentDto, 'number'>;

export const ApartmentViewMobilePopup = ({
  children,
  onClose,
  isOpen,
  number,
  isCottage = false,
}: Props) => {
  const { height, width } = useWindowSize();

  const [isFullInfo, setFullInfo] = useState(false);

  const _onClose = useCallback(() => {
    setFullInfo(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const onOpen = useCallback(() => {
    setFullInfo(true);
  }, []);

  // Десктопная логика (hammer), если нужно
  const { swipeButton, position } = useApartmentViewHammer({
    onOpen,
    onClose: _onClose,
    isFullInfo,
  });

  const getTransform = () => {
    if (!isOpen) {
      return `translateY(100vh)`;
    }
    if (isFullInfo) {
      return `translateY(calc(var(--navbar-height) - var(--show-full-info-button-height)))`;
    }
    return `translateY(${(height || 0) - 185 - 12 - 12 + position}px)`;
  };

  return (
    !isCottage ? (
      <>
        <div
          ref={(ref) => {
            swipeButton.current = ref;
          }}
          style={{
            transform: getTransform(),
          }}
          className={cc([
            styles.contentWrapper,
            {
              [styles.contentWrapperIsOpen]: isOpen,
              [styles.contentWrapperFull]: isFullInfo,
            },
          ])}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button type="button" className={styles.showFullInfoButton} />
          <div
            style={{
              overflow: isFullInfo ? 'auto' : 'hidden',
            }}
            className={styles.contentScrollbar}
          >
            {children}
          </div>
        </div>

        <MobilePopupHeader
          className={cc([
            styles.headerWrapper,
            {
              [styles.headerWrapperShow]: isFullInfo,
            },
          ])}
          IconBack={<Icon src={arrowLeftColor} size={24} />}
          onClose={_onClose}
          headerChildren={
            <Text align="center" size="L" weight="700">
              {isCottage ? `Коттедж ${number}` : `Квартира ${number}`}
            </Text>
          }
        />
      </>
    ) : (
      <BottomSheet
        isOpen={isOpen}
        onClose={_onClose}
        withBackdrop={false}
        minHeight={220}
      >
        {children}
      </BottomSheet>
    )
  );
};
