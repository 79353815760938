import React from 'react';
import {useTheme} from "../../context/theme/useTheme";

const CloseIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15 2.4094L9.4094 8L15 13.5906L13.5906 15L8 9.4094L2.4094 15L1 13.5906L6.5906 8L1 2.4094L2.4094 1L8 6.5906L13.5906 1L15 2.4094Z"
          fill="#5B5B61"/>
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15 2.4094L9.4094 8L15 13.5906L13.5906 15L8 9.4094L2.4094 15L1 13.5906L6.5906 8L1 2.4094L2.4094 1L8 6.5906L13.5906 1L15 2.4094Z"
          fill="#AFB4CB"/>
      </svg>
    )
  );
};

export default CloseIcon;
